import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Wrapper = styled.div`
  background: ${theme.TERTIARYBACKGROUNDCOLOR};
  padding: 24px;
  border-radius: 12px;
`
export const MainWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const OrderDetailSection = styled.div`
  display: flex;
  gap: 20px;
`
export const ImageWrapper = styled.div``

export const OrderDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const OrderIdWrapper = styled.div`
  display: flex;
  gap: 5px;
`
export const OrderId = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.PRIMARYTEXTCOLOR};
`
export const IssueCountWrapper = styled.div`
  display: flex;
  gap: 5px;
`
export const CountTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.PRIMARYGREYCOLOR};
`
export const IssueCount = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.DESCRIPTIONTEXTCOLOR};
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    text-transform: none;
    border: 1px solid ${theme.PRIMARYCOLOR};
    border-radius: 8px;
  }
`
