import { useState, useEffect, useMemo } from 'react'
import useGet from 'hooks/useGet'
import ComplaintsCard from 'views/Complaints/ComplaintsCard'
import { Container } from 'styles/ComplaintsCard'

const ActiveComplaints = ({ orderId }) => {
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalCount: 0,
    postPerPage: 10,
    status: 'activeIssues',
  })

  // Memoize apiUrl to prevent unnecessary re-renders
  const apiUrl = useMemo(() => {
    let url = `/issueApis/v1/getIssues?pageNumber=${pagination.currentPage}&limit=${pagination.postPerPage}&state=${pagination.status}`
    if (orderId) {
      url += `&search=${orderId}`
    }

    return url
  }, [pagination.currentPage, pagination.postPerPage, pagination.status, orderId])

  const { data: getIssues, refetch } = useGet('get-issues', apiUrl)

  // Update totalCount only when it changes to prevent unnecessary re-renders
  useEffect(() => {
    if (getIssues?.totalCount !== pagination.totalCount) {
      setPagination((prev) => ({
        ...prev,
        totalCount: getIssues?.totalCount || 0,
      }))
    }
  }, [getIssues?.totalCount, pagination.totalCount])

  const issueList = getIssues?.issues

  // Refetch data when orderId changes
  useEffect(() => {
    refetch()
  }, [orderId, refetch])

  return (
    <Container>
      {issueList?.map((issues, index) => (
        <div key={index}>
          <ComplaintsCard issueDetails={issues} />
        </div>
      ))}
    </Container>
  )
}

export default ActiveComplaints
