import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 33px;
  .mui-tab-class {
    padding: 0;
  }
  @media (max-width: 767px) {
    margin: 0 16px;
    width: unset;
    gap: 18px;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const SearchWrapper = styled.div``
export const SearchContent = styled.div`
  border-radius: 10px;
  display: flex;
  border: 1px solid #e8e8e8;
  background: #f6f6f6;
  width: 320px;
  @media (max-width: 767px) {
    width: 220px;
    }
`

export const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${theme.TERTIARYCOLORDARK};
  text-align: left;
  @media (max-width: 767px) {
    font-size: 20px;
  }
`
