// ProfileRoutes.js
import React, { useState, useEffect } from 'react'
import { Route, Switch, useHistory, Redirect, useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'
import CheckoutSideBar from 'components/checkoutSideBar'
import MyOrder from 'components/myOrders'
import WishlistDetails from 'components/whislist'
// import MyTickets from 'components/application/my-tickets/myTickets'
import Complaints from 'views/Complaints'
import ModalComponent from 'components/common/Modal'
import UserProfile from 'views/UserProfile'
import ConfirmationModal from 'views/ConfirmationModal'
import { MainContainer } from 'styles/checkoutOrders'
import MyTickets from 'components/application/my-tickets/myTickets'

const ProfileRoutes = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [logout, setLogout] = useState(false)
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    switch (location.pathname) {
      case '/profile/orders':
        setActiveIndex(0)
        break
      case '/profile/complaint':
        setActiveIndex(1)
        break
      case '/profile/wishlist':
        setActiveIndex(2)
        break
      case '/profile/user-info':
        setActiveIndex(3)
        break
      default:
        setActiveIndex(0)
        break
    }
  }, [location.pathname])

  const handleSidebarClick = (value) => {
    switch (value) {
      case 'my-order':
        setActiveIndex(0)
        ReactGA.event({
          category: 'Sidebar',
          action: 'Click',
          label: 'My Order',
        })
        history.push('/profile/orders')
        break
      case 'complaints':
        setActiveIndex(1)
        ReactGA.event({
          category: 'Sidebar',
          action: 'Click',
          label: 'Complaints',
        })
        history.push('/profile/complaint')
        break
      case 'wishlist':
        setActiveIndex(2)
        ReactGA.event({
          category: 'Sidebar',
          action: 'Click',
          label: 'Wishlist',
        })
        history.push('/profile/wishlist')
        break
      case 'my-info':
        setActiveIndex(3)
        ReactGA.event({
          category: 'Sidebar',
          action: 'Click',
          label: 'My Info',
        })
        history.push('/profile/user-info')
        break
      case 'sign-out':
        setLogout(true)
        ReactGA.event({
          category: 'Sidebar',
          action: 'Click',
          label: 'Sign Out',
        })
        break
      default:
        setActiveIndex(0)
        history.push('/profile/orders')
    }
  }

  return (
    <MainContainer>
      <CheckoutSideBar
        handleSidebarClick={handleSidebarClick}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
      <div style={{ flex: 1 }}>
        <Switch>
          <Route path="/profile/orders" component={MyOrder} />
          <Route path="/profile/wishlist" component={WishlistDetails} />
          <Route path="/profile/user-info" component={UserProfile} />
          <Route path="/profile/complaint" component={Complaints} />
          <Route path="/profile/complaints/:issuesOrderId" component={MyTickets} />
          <Redirect from="/profile" to="/profile/orders" />
        </Switch>
      </div>
      {logout && (
        <ModalComponent open={logout} onClose={() => setLogout(false)}>
          <ConfirmationModal
            onClose={() => setLogout(false)}
            title={'Are you sure want to sign out ?'}
            type={'logout'}
          />
        </ModalComponent>
      )}
    </MainContainer>
  )
}

export default ProfileRoutes
